import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import FeatureBlock from 'common/components/FeatureBlock';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import Fade from 'react-reveal/Fade';

import style from './feature.css';

const FeatureSection = ({
  sectionWrapper,
  secTitleWrapper,
  secSubText,
  secText,
  secHeading,
  row,
  col,
  FeatureItemStyle,
  iconStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        FEATURES {
          title
          description
          descriptionSub
          icon {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <Box {...sectionWrapper} as='section' id='function_section'>
      <Container className={'container feature_container'}>
        <Box {...secTitleWrapper}>
          <Heading {...secHeading} content='これらのお悩み' />
          <Text content='pittoで全て解決！' {...secSubText}></Text>
        </Box>

        <Box {...row}>
          {Data.saasModernJson.FEATURES.map((item, index) => (
            <Box
              {...col}
              key={`feature-item-${index}`}
              className={`feature-item-${index}`}
            >
              <Fade left>
                <Image
                  {...iconStyle}
                  src={item.icon.publicURL}
                  alt={`feature-item-icon-${index + 1}`}
                />
              </Fade>

              {/* <Heading
                content={item.title}
                {...featureTitle}
                className={`feature-item-heading`}
              ></Heading> */}
              <h1 className={'feature_item_heading'}>{item.title}</h1>
              <Text
                {...secText}
                style={{ marginTop: '40px' }}
                content={item.description}
              ></Text>
            </Box>
          ))}
        </Box>
        <div class='btn_wrapper'>
          <a href='/function' class='btn bgskew'>
            <span>詳しく見る</span>
          </a>
        </div>
      </Container>
    </Box>
  );
};

FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  FeatureItemStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

FeatureSection.defaultProps = {
  sectionWrapper: {
    pt: ['50px', '50px', '50px', '50px', '50px'],
    pb: ['20px', '50px', '80px', '80px', '120px'],
    zIndex: 1,
    backgroundColor: '#fff',

    height: ['220vh', '160vh', '160vh', '160vh', '200vh'],
  },

  secTitleWrapper: {
    textAlign: 'left',
    mb: ['60px', '100px'],
  },
  secSubText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: ['24px', '24px', '24px', '24px'],
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#696969',
    mb: '5px',
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'left',
    fontSize: ['15px', '15px', '15px', '18px'],
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#696969',
    mb: '5px',
  },
  secHeading: {
    marginTop: '40px',
    textAlign: 'center',
    fontSize: ['36px', '36px', '36px', '36px', '48px'],
    fontWeight: '700',
    color: 'primary',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['-30px', '-30px', '-30px', '-30px', '-30px'],
    mr: ['-30px', '-30px', '-30px', '-30px', '-45px'],
  },
  col: {
    width: [1, 1 / 2, 1 / 2],
    pl: ['30px', '30px', '30px', '30px', '45px'],
    pr: ['30px', '30px', '30px', '30px', '45px'],
    mb: ['40px', '40px'],
    textAlign: 'center',
  },
  FeatureItemStyle: {
    alignItems: 'center',
  },
  iconStyle: {
    width: ['120px', '120px', '180px', '180px', '300px'],
    height: ['120px', '120px', '180px', '180px', '300px'],
    borderRadius: '50%',
    display: 'inline-block',
  },
  contentStyle: {},
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '700',
    color: 'primary',

    letterSpacing: '-0.025em',
    lineHeight: '1.5',
    mb: ['10px', '15px'],
  },
  featureDescription: {
    fontSize: '15px',
    fontWeight: '400',
    color: '#5d646d',
    letterSpacing: '-0.025em',
    lineHeight: '1.88',
    mb: 0,
  },
};

export default FeatureSection;
